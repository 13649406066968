var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPayGuide,
          expression: "showPayGuide"
        }
      ],
      staticClass: "pay-dialog"
    },
    [
      _c("div", { staticClass: "payWind" }, [
        _c("div", { staticClass: "wrap-text" }, [
          _c("div", { staticClass: "t1" }, [
            _vm._v(_vm._s(_vm.$t("LeaveupDialogTxt")))
          ])
        ]),
        _vm._m(0),
        _c("div", { staticClass: "tit" }, [
          _vm._v(_vm._s(_vm.$t("LeaveupDialogTimeDownTxt")))
        ]),
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.hours))]),
            _c("div", { staticClass: "flag" }, [_vm._v(_vm._s(_vm.$t("Hrs")))])
          ]),
          _c("li", [
            _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.minutes))]),
            _c("div", { staticClass: "flag" }, [
              _vm._v(_vm._s(_vm.$t("Mins")))
            ]),
            _c("div", { staticClass: "split" }, [_vm._v(":")])
          ]),
          _c("li", [
            _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.seconds))]),
            _c("div", { staticClass: "flag" }, [
              _vm._v(_vm._s(_vm.$t("Secs")))
            ]),
            _c("div", { staticClass: "split" }, [_vm._v(":")])
          ])
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.gopay } }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("LeaveupDialogBtnTxt")))])
        ]),
        _c("div", { staticClass: "give", on: { click: _vm.giveupHandler } }, [
          _vm._v(_vm._s(_vm.$t("LeaveupDialogGiveUpBtnTxt")))
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "percent" }, [
      _c("img", { attrs: { src: require("@/assets/img/percent.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }