<template>
  <div class="pay-dialog" v-show="showPayGuide">
    <div class="payWind">
      <div class="wrap-text">
        <div class="t1">{{ $t("LeaveupDialogTxt") }}</div>
        <!-- <div class="t2">Extra 7 days on top ofthe 67%</div>
        <div class="t3">Discount already offered!</div> -->
      </div>
      <div class="percent">
        <img src="@/assets/img/percent.png" />
      </div>
      <div class="tit">{{ $t("LeaveupDialogTimeDownTxt") }}</div>
      <ul>
        <!-- <li style="margin-left: -30px">
          <div class="time">{{ days }}</div>
          <div class="flag">{{ $t("day") }}</div>
        </li> -->
        <li>
          <div class="time">{{ hours }}</div>
          <div class="flag">{{ $t("Hrs") }}</div>
        </li>
        <li>
          <div class="time">{{ minutes }}</div>
          <div class="flag">{{ $t("Mins") }}</div>
          <div class="split">:</div>
        </li>
        <li>
          <div class="time">{{ seconds }}</div>
          <div class="flag">{{ $t("Secs") }}</div>
          <div class="split">:</div>
        </li>
      </ul>
      <div class="btn" @click="gopay">
        <span>{{ $t("LeaveupDialogBtnTxt") }}</span>
      </div>
      <div class="give" @click="giveupHandler">{{ $t("LeaveupDialogGiveUpBtnTxt") }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPayGuide: false,
      deadline: new Date().getTime() + 1000 * 60 * 30,
    };
  },
  i18n: require("../../capaingni18n"),
  watch: {
    showPayGuide (val) {
      if (val) {
        this.getCountDown();
      } else {
        this.clearCountDown();
      }
    }
  },
  methods: {
    giveupHandler() {
      this.showPayGuide = false;
      setTimeout(() => {
        this.$emit("callback");
      }, 300);
    },
    gopay() {
      this.showPayGuide = false;
      this.$emit("goPay");
    },
    // 倒计时
    getCountDown() {
      this.isCountDown = true
      this.countDown(new Date().getTime() + 1000 * 60 * 30)
    },
    // 清除倒计时
    clearCountDown () {
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
      }
    }
  },
  beforeDestroy() {
    this.clearCountDown()
  },
};
</script>
<style lang="less" scoped>
.pay-dialog {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  .payWind {
    width: 696px;
    height: 737px;
    position: absolute;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(../../../../../assets/img/pay_bg.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    .wrap-text {
      width: 458px;
      height: 130px;
      margin: 0 auto;
      background: url(../../../../../assets/img/textBg.png) no-repeat;
      margin-top: 140px;
      display: flex;
      align-items: center;
      .t1 {
        // padding-top: 12px;
        font-weight: 400;
        font-size: 20px;
        color: #5d2309;
        height: 38;
        line-height: 38px;
        width: 100%;
      }
      .t2 {
        height: 27px;
        font-family: Arial, Arial;
        font-weight: bold;
        font-size: 24px;
        color: #ff0000;
        line-height: 28px;
      }
      .t3 {
        font-weight: 400;
        font-size: 20px;
        color: #5d2309;
        height: 38;
        line-height: 38px;
      }
    }
    .percent {
      width: 250px;
      height: 200px;
      margin: 0 auto;
      position: relative;
      img {
        width: 250px;
        height: 200px;
        position: absolute;
        top: -16px;
        left: 0;
      }
    }
    .tit {
      font-weight: 900;
      font-size: 20px;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
      height: 28px;
      margin-top: -25px;
    }
    ul {
      width: 340px;
      margin: 0 auto;
    }
    ul {
      display: flex;
      margin-top: 12px;

      li {
        display: inline-block;
        width: 70px;
        height: 79px;
        margin-left: 30px;
        border-radius: 7px;
        background: #fff;
        text-align: center;
        position: relative;
        .time {
          margin-top: 12px;
          font-weight: bold;
          font-size: 36px;
          color: #ff7700;
          height: 41px;
          line-height: 41px;
        }
        .flag {
          width: 100%;
          font-weight: bold;
          margin-top: 4px;
          font-size: 12px;
          color: #5d3c10;
          height: 14px;
          line-height: 14px;
          text-align: center;
        }
        .split {
          height: 79px;
          line-height: 79px;
          color: #fff;
          font-weight: bold;
          font-size: 36px;
          position: absolute;
          left: -20px;
          top: 0;
        }
      }
    }
    .btn {
      width: 408px;
      height: 66px;
      background: linear-gradient(85deg, #ffac00 0%, #f85760 100%);
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.16),
        inset 0px 3px 6px 1px rgba(255, 255, 255, 0.57);
      border-radius: 100px 100px 100px 100px;
      border: 4px solid #ffffff;
      text-align: center;
      line-height: 66px;
      margin: 0 auto;
      margin-top: 25px;
      cursor: pointer;

      span {
        font-weight: 900;
        font-size: 24px;
        color: #ffffff;
        line-height: 0px;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .give {
      margin-top: 25px;
      text-align: center;
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
