module.exports = {
  messages: {
    CN: {
      ChooseYourCustomerSearchMethod: "客户查找方式",
      InputBasicInformation: "基础信息录入",
      CampaignsSettings: "任务设置",
      Pleaseenterthecampaignname: "请输入任务名称",
      SaveandContinue: "保存并继续",
      back: "返回",
      SearchMethodSelection: "搜索方式选择",
      ConnectYourLinkedInAccount: "绑定领英账号",
      ConfigureLinkedInProxy: "代理设置",
      SelectfromYourConnectedContacts: "一度人脉：从你的好友中选择",
      SearchByUrl: "领英URL搜索：通过领英搜索URL寻找目标客户",
      bindLinkAcc: "绑定领英账号",
      linkEmal: "邮箱",
      linkPwd: "密码",
      timeZone: "时区",
      ConfigureLinkedInProx: "代理设置",
      ManualConfiguration: "人工配置",
      SelectProxyRegion: "代理区域",
      Connect: "连接",
      EnterLinkedInSearchURL: "请输入领英搜索URL",
      CompanyInformation: `公司介绍`,
      CompanyInformationCont: `基础信息：StrategyBrain是一家致力于通过人工智能技术提高中小企业人力资源部门员工工作能力，推动中小企业改善人力资源成本，提高中小企业市场竞争力的创新公司。
官方网站：www.strategybrain.ca
地址：StrategyBrain位于加拿大多伦多。
公司介绍：StrategyBrain致力于用最前沿的AI科技来解决实际问题，降低社会的不平等，推动社会进步。这种文化认同和社会责任感为公司在实现其使命、愿景和价值观的过程中提供了坚实的基础。`,
      ProductInformation: "产品介绍",
      ProductInformationCont: `产品功能：
 1.产品以AI的形式协助用户自动在linkedIn上找到潜在客户，自动建立联系，自动沟通产品购买意向，解答产品疑问，阐述企业发展理念，产品优势，判断客户采购意向
2. 根据企业产品待销售产品信息设计客户画像
3. 根据客户画像自动寻找客户，根据客户背景信息，设计个性化打招呼用语，自动建立联系
4. 依据待销售产品特点和客户背景信息，设计销售沟通话术，与客户自动沟通产品购买意向，解答产品疑问，阐述企业发展理念，产品优势，判断客户采购意向
产品特点：
1. AI 7*24小时全年无休，自动工作，无语言障碍全球拓展客户
2. 个性化沟通和解决方案生成，提高客户建立联系成功率及沟通成功率（英文叫engagement）
3. 通过个性化能力，引导潜在客户输出痛点，并自主地提供对应的解决方案，增加潜在客户的信任。
4. 替代销售重复性工作，聚焦销售策略和意向客户的最终沟通。
5. AI可以学习企业的信息，通过企业信息设计的销售话术和介绍企业以及案例分享。
6. 替代在Linkedin的所有客户拓展和销售工作。
7. AI与人协同工作，将重复工作交给AI，销售聚焦在有意向客户的沟通上，提高整个产品销售的成功率。
使用方法：
1. Linkedin账户托管给SaaS AI平台，AI Sales Rep会使用Linkedin账户根据提供的产品资料和客户拓展要求自动检索Linkedin潜在客户，自动沟通客户，将有购买意向的客户联系方式记录下来，使用者就可以通过联系方式直接联系客户安排进一步正式的销售沟通。
2. 在使用前需要向AI Sales Rep提供产品资料、企业资料以方便机器人能够根据产品信息提供潜在客户画像供使用者采纳或修改，以及在与潜在客户沟通时，AI可以向潜在客户介绍产品和企业，回答潜在客户问题。
3. 机器人在使用前需要设置Linkedin账户所在地区域，以确保机器人可以添加到正确区域内客户
常见问题：
1. AI使用的是客户提供的Linkedin账户，如果AI软件不在使用，客户可以删除相关信息，将linkedin账户收回
2. AI不会泄露账户隐私和密码，因为AI有隐私保护设计，每一位客户的信息都会被去除敏感信息后单独加密存储
3. Saas软件不需要安装，对电脑配置没有要求，兼容windows和Mac，甚至手机都可以访问
4. AI平台可以个人使用，也可以团队使用，多Linkedin账户使用
5. AI软件使用不复杂，非常简单，只需要提供Linkedin账户根据系统提示进行信息录入既可以开始使用`,
      CompetitorInformation: "竞品信息",
      CompetitorInformationCont: "竞品名称：dux-soup,Expandi.io,meetalfred",
      SuccessStories: "成功案例",
      SuccessStoriesCont: `case1:Cost Reduction and Efficiency: AI Sales Tools Enhance HR Productivity and Employee Satisfaction
Implementing AI sales tools can significantly reduce costs and improve efficiency, enhancing HR productivity and employee satisfaction. BridgePoint B2B Leads Solutions integrated StrategyBrain’s AI Sales Rep to address challenges such as high management costs, employee turnover, operational inefficiencies, and limited revenue growth. The AI’s capabilities in customer profiling, personalized outreach, and 24/7 sales conversations allowed the sales team to shift from repetitive tasks to strategic sales, greatly improving overall efficiency and reducing operational expenses. As a result, the implementation led to a 60% reduction in operational costs, a 20% increase in employee satisfaction, and a 5% revenue growth, successfully driving the company's expansion in the global market.
case2：......`,
      PricingInformation: "价格信息",
      PricingInformationCont: `免费试用：免费测试使用，可以直接注册并体验产品
付费价格：每月9.9美元价格
是否优惠：是，订购可享受每月9.9美元`,
      ContactInformation: "联系方式",
      ContactInformationCont: `邮箱：
电话：`,
      addMore: "添加更多信息",
      CustomContent: "自定义内容",
      inputCustomContent: "请输入自定义内容",
      BriefSummary: "简短总结",
      BriefSummaryIntro: `简短总结能够帮助strategyBrain更好的理解你的公司和产品，请确保包含所有的关键点`,
      SetConversationLimits: "对话限制",
      SetConversationLimitsCont:
        "对话限制可以告诉AI不能跟用户进行哪些方面的对话",
      sendLimit: "每日发送添加好友请求数",
      dianzan: "给用户的第一条post点赞",
      capaginName: "任务名称",

      inputLinkAccount: "请选择Linkedin账号",
      inputContent: "请输入内容",
      rightAccount: "请输入正确的邮箱领英账号",
      inputPwd: "请输入密码",
      selectTimezone: "请选择时区",
      selectRegion: "请选择代理区域",
      selectUrl: "请输入正确的Linkedin URL",
      editTitle: "请编辑自定义标题",
      shortDes: "请输入简短总结",
      dialogLimit: "请输入对话限制",
      All: "所有的",
      bindTips:
        "请准备好确认您LinkedIn账户的PIN码。该PIN码将由LinkedIn发送到LinkedIn账户的邮箱，具体取决于安全设置，也可能通过短信发送。",
      inputCode: "请输入验证码",
      confirmDel: "确认要删除此任务",
      NextStep: "下一步",

      Account: "账号",
      TimeZone: "时区",
      Proxy: "代理",
      Connected: "已连接",
      ObtainProxyIP: "获取代理IP",
      VerifyLink: "校验Linkedin账号密码",
      LinkedInAccountLink: "Linkedin账号链接",
      ContinueConnect: "继续链接",
      selectHistory: "可以选择历史记录",
      findUrl: "如何找到Linkedin URL",
      loginAccount: "登录您的Linkedin账号",
      Enterkeywords: "在搜索框中输入关键词，点击enter或者see all results",
      SelectThePeople:
        "选择people筛选，同时在Filter中设置更多搜索条件，点击show results",
      copyURL: "复制导航栏中的URL地址，到此输入框中",
      ReferenceDocument: "参考文档",
      conversationRestrictions: "请输入对话的限制，例如禁止讲笑话...",
      Save: "保存",
      day: "天",
      Hrs: "时",
      Mins: "分",
      Secs: "秒",
      LeaveupDialogTitle: '确定要取消支付吗？',
      LeaveupDialogTxt: '现在支付在原有折扣上再额外赠送7天',
      LeaveupDialogTimeDownTxt: '优惠倒计时',
      LeaveupDialogBtnTxt: '立刻升级',
      LeaveupDialogGiveUpBtnTxt: '放弃优惠',
    },
    US: {
      ChooseYourCustomerSearchMethod: "Choose Your Customer Search Method",
      InputBasicInformation:
        "Input Basic Information Before Launching Campaigns",
      CampaignsSettings: "Campaigns Settings",
      Pleaseenterthecampaignname: "Please enter the campaign name",
      SaveandContinue: "Save and Continue",
      back: "Back",
      SearchMethodSelection: "Search Method Selection",
      ConnectYourLinkedInAccount: "Connect Your LinkedIn Account",
      ConfigureLinkedInProxy: "Configure LinkedIn Proxy",
      SelectfromYourConnectedContacts: "Select from Your Connected Contacts",
      SearchByUrl: "LinkedIn URL Search: Find Target Customers via URL",
      bindLinkAcc: "Connect Your LinkedIn Account",
      linkEmal: "LinkedIn Email Address",
      linkPwd: "LinkedIn Password",
      timeZone: "Select Your Time Zone",
      ConfigureLinkedInProx: "Configure LinkedIn Proxy",
      ManualConfiguration: "Manual Configuration",
      SelectProxyRegion: "Select Proxy Region",
      Connect: "Connect",
      EnterLinkedInSearchURL: "Enter LinkedIn Search URL",
      CompanyInformation: `Company Information`,
      CompanyInformationCont: `Basic Information: StrategyBrain is an innovative company dedicated to enhancing the capabilities of HR departments in SMEs through artificial intelligence, reducing HR costs, and boosting the competitiveness of SMEs in the market.
Official Website: www.strategybrain.ca
Address: StrategyBrain is located in Toronto, Canada.
Company Introduction: StrategyBrain is committed to using cutting-edge AI technology to solve real-world problems, reduce social inequalities, and foster social progress. Our culture of identification and sense of social responsibility solidly underpins our mission, vision, and values.`,
      ProductInformation: "Product Information",
      ProductInformationCont: `Product Features:
1.Automate Customer Engagement on LinkedIn.
2.Designs customer profiles based on information about products pending sale.
3.Automated Customer Discovery and Engagement.
4.Dynamic Sales Communication Tailored by AI.
`,
      CompetitorInformation: "Competitor Information",
      CompetitorInformationCont:
        "Competitor Names: Dux-Soup, Expandi.io, MeetAlfred",
      SuccessStories: "Success Stories",
      SuccessStoriesCont: `case1:Cost Reduction and Efficiency: AI Sales Tools Enhance HR Productivity and Employee Satisfaction
Implementing AI sales tools can significantly reduce costs and improve efficiency, enhancing HR productivity and employee satisfaction. BridgePoint B2B Leads Solutions integrated StrategyBrain’s AI Sales Rep to address challenges such as high management costs, employee turnover, operational inefficiencies, and limited revenue growth. The AI’s capabilities in customer profiling, personalized outreach, and 24/7 sales conversations allowed the sales team to shift from repetitive tasks to strategic sales, greatly improving overall efficiency and reducing operational expenses. As a result, the implementation led to a 60% reduction in operational costs, a 20% increase in employee satisfaction, and a 5% revenue growth, successfully driving the company's expansion in the global market.
case2：......`,
      PricingInformation: "Pricing Information",
      PricingInformationCont: `Free Trial: Test the product for free by directly registering and experiencing it.
Subscription Price: $9.90 per month
Discount Available: Yes, subscribe at $9.90 per month`,
      ContactInformation: "Contact Information",
      ContactInformationCont: `Email：
Phone：`,
      addMore: "Add More Information",
      CustomContent: "Custom Content",
      inputCustomContent: "Enter Custom Content",
      BriefSummary: "Brief Summary",
      BriefSummaryIntro: `A brief summary will help StrategyBrain better understand your company and products. Please ensure all key points are included.`,
      SetConversationLimits: "Set Conversation Limits",
      SetConversationLimitsCont:
        "Dialogue restrictions inform the AI about topics that are off-limits in conversations with users.",
      sendLimit: "Set Daily Connection Request Limit",
      dianzan:
        "Automatically Like the First Post When Connecting with Potential Customers",
      capaginName: "Campaign Name",
      inputLinkAccount: "Please select your LinkedIn account",
      inputContent: "Please enter the content",
      rightAccount: "Please enter a valid LinkedIn email account",
      inputPwd: "Please enter the password",
      selectTimezone: "Please select a time zone",
      selectRegion: "Please select a proxy region",
      selectUrl: "Please enter a valid LinkedIn URL",
      editTitle: "Please edit the custom title",
      shortDes: "Please input your short_description",
      dialogLimit: "Please input conversation limit",
      All: "All them",
      bindTips: `Be ready to confirm the PIN for your LinkedIn account due
                    to 2-factor authorization. It will be sent by LinkedIn to
                    the email address of the LinkedIn account. Depending on the
                    security settings it may also be sent via SMS.`,
      inputCode: "Please input code",
      confirmDel: "Are you sure you want to delete this task?",
      NextStep: "Next Step",

      Account: "Account",
      TimeZone: "Time Zone",
      Proxy: "Proxy",
      Connected: "Connected",
      ObtainProxyIP: "Obtain Proxy IP",
      VerifyLink: "Verify LinkedIn Account Credentials",
      LinkedInAccountLink: "LinkedIn Account Link",
      ContinueConnect: "Continue Connect",
      selectHistory: "You can select history records",
      findUrl: "How to find the LinkedIn URL",
      loginAccount: "Log in to your LinkedIn account",
      Enterkeywords:
        'Enter keywords in the search box, then click Enter or "See all results"',
      SelectThePeople:
        'Select the "People" filter, set additional search criteria in the filters, and click "Show results"',
      copyURL:
        "Copy the URL address from the navigation bar into this input box",
      ReferenceDocument: "Reference Document",
      conversationRestrictions:
        "Please specify any conversation restrictions, e.g., no jokes allowed...",
      Save: "Save",
      day: "Days",
      Hrs: "Hrs",
      Mins: "Mins",
      Secs: "Secs",
      LeaveupDialogTitle: 'Wait!',
      LeaveupDialogTxt: 'Pay now to get an extra 7 days on top of your existing discount',
      LeaveupDialogTimeDownTxt: 'Discount countdown',
      LeaveupDialogBtnTxt: 'Upgrade now',
      LeaveupDialogGiveUpBtnTxt: 'Give up the discount',
    },
    AR: {
      ChooseYourCustomerSearchMethod: "اختر طريقة البحث عن عملائك",
      InputBasicInformation: "إدخال المعلومات الأساسية قبل إطلاق الحملات",
      CampaignsSettings: "إعدادات الحملات",
      Pleaseenterthecampaignname: "يرجى إدخال اسم الحملة",
      SaveandContinue: "احفظ وتابع",
      back: "العودة",
      SearchMethodSelection: "طريقة اختيار البحث",
      ConnectYourLinkedInAccount: "ربط حساب لينكد إن الخاص بك",
      ConfigureLinkedInProxy: "تكوين الوكيل لينكد إن",
      SelectfromYourConnectedContacts:
        "اختر من جهات الاتصال المتصلة التي تعرفها",
      SearchByUrl: "البحث عن العملاء المستهدفين عبر URL على LinkedIn",
      bindLinkAcc: "LinkedIn أدخل بيانات اعتماد حسابك على ",
      linkEmal: " LinkedIn عنوان البريد الإلكتروني على ",
      linkPwd: "LinkedIn كلمة المرور على ",
      timeZone: "اختر منطقتك الزمنية",
      ConfigureLinkedInProx: "تكوين الوكيل لينكد إن",
      ManualConfiguration: "التكوين اليدوي",
      SelectProxyRegion: "اختر منطقة البروكسي",
      Connect: "اتصال",
      EnterLinkedInSearchURL: "LinkedInأدخل رابط بحث ",
      CompanyInformation: `معلومات الشركة`,
      CompanyInformationCont: `معلومات أساسية: StrategyBrain هي شركة مبتكرة مكرسة لتعزيز قدرات أقسام الموارد البشرية في الشركات الصغيرة والمتوسطة من خلال الذكاء الاصطناعي، وتقليل تكاليف الموارد البشرية، وزيادة تنافسية الشركات الصغيرة والمتوسطة في السوق.
الموقع الرسمي: www.strategybrain.ca
العنوان: تقع StrategyBrain في تورونتو، كندا.
مقدمة الشركة: StrategyBrain ملتزمة باستخدام أحدث تكنولوجيا الذكاء الاصطناعي لحل المشكلات الواقعية، وتقليل عدم المساواة الاجتماعية، وتعزيز التقدم الاجتماعي. هوية الثقافة وإحساس المسؤولية الاجتماعية يوفران أساسًا صلبًا للشركة في تحقيق مهمتها ورؤيتها وقيمها.`,
      ProductInformation: "معلومات المنتج",
      ProductInformationCont: `ميزات المنتج:
1. يساعد المنتج على شكل ذكاء اصطناعي المستخدمين على إيجاد العملاء المحتملين تلقائيًا على LinkedIn، وبناء علاقات تلقائية، والتواصل بشأن نوايا شراء المنتجات، والإجابة على الأسئلة المتعلقة بالمنتجات، وشرح فلسفة تطور الشركة ومزايا المنتج، وتقييم نوايا شراء العملاء.
2. يصمم صورًا للعملاء استنادًا إلى المعلومات المتعلقة بالمنتجات التي تنتظر البيع.
3. يكتشف العملاء تلقائيًا ويتفاعل معهم.
4. يقدم التواصل المبيعاتي الديناميكي المخصص من قبل الذكاء الاصطناعي.`,
      CompetitorInformation: "معلومات المنافسين",
      CompetitorInformationCont:
        "أسماء المنافسين: دوكس-سوب، إكسباندي.آي أو، ميتألفريد",
      SuccessStories: "قصص النجاح",
      SuccessStoriesCont: `الحالة1: تقليل التكاليف وزيادة الكفاءة: أدوات المبيعات الذكية تعزز إنتاجية الموارد البشرية ورضا الموظفين
تطبيق أدوات المبيعات الذكية يمكن أن يقلل التكاليف بشكل ملحوظ ويحسن الكفاءة، مما يعزز إنتاجية الموارد البشرية ورضا الموظفين. دمج حلول BridgePoint B2B Leads Solutions لأداة مبيعات StrategyBrain AI لمواجهة تحديات مثل ارتفاع تكاليف الإدارة، ودوران الموظفين، وعدم كفاءة العمليات، ومحدودية نمو الإيرادات. سمحت قدرات الذكاء الاصطناعي في تصنيف العملاء، والتواصل الشخصي، ومحادثات المبيعات التي تجري على مدار الساعة لفريق المبيعات بالانتقال من المهام المتكررة إلى المبيعات الاستراتيجية، مما أدى إلى تحسين الكفاءة العامة بشكل كبير وتقليل التكاليف التشغيلية. نتيجة لذلك، أدى التطبيق إلى تقليل التكاليف التشغيلية بنسبة 60٪، وزيادة رضا الموظفين بنسبة 20٪، ونمو الإيرادات بنسبة 5٪، مما ساهم بنجاح في توسيع نطاق الشركة في السوق العالمية.
الحالة2: ......`,
      PricingInformation: "معلومات التسعير",
      PricingInformationCont: `التجربة المجانية: اختبر المنتج مجاناً عبر التسجيل المباشر وتجربته.
سعر الاشتراك: 9.90 دولار أمريكي شهرياً
الخصم المتوفر: نعم، اشترك بسعر 9.90 دولار شهرياً`,
      ContactInformation: "معلومات الاتصال",
      ContactInformationCont: `البريد الإلكتروني:
الهاتف:`,
      addMore: "أضف المزيد من المعلومات",
      CustomContent: "محتوى مخصص",
      inputCustomContent: "الرجاء إدخال المحتوى المخصص",
      BriefSummary: "ملخص موجز",
      BriefSummaryIntro: `سيساعد الملخص المختصر StrategyBrain على فهم شركتك ومنتجاتك بشكل أفضل. الرجاء التأكد من تضمين جميع النقاط الرئيسية.`,
      SetConversationLimits: "تحديدات المحادثة",
      SetConversationLimitsCont:
        "تُعلم قيود الحوار الذكاء الاصطناعي بالمواضيع التي يُحظر مناقشتها مع المستخدمين.",
      sendLimit: "ضع حدًا يوميًا لطلبات الاتصال",
      dianzan:
        "قم تلقائيًا بالإعجاب بالمنشور الأول عند الاتصال بالعملاء المحتملين",
      capaginName: "اسم الحملة",

      inputLinkAccount: "يرجى اختيار حسابك على LinkedIn",
      inputContent: "يرجى إدخال المحتوى",
      rightAccount: "يرجى إدخال بريد إلكتروني صالح لحساب LinkedIn",
      inputPwd: "يرجى إدخال كلمة المرور",
      selectTimezone: "يرجى اختيار المنطقة الزمنية",
      selectRegion: "يرجى اختيار منطقة الوكيل",
      selectUrl: "يرجى إدخال رابط LinkedIn صالح",
      editTitle: "يرجى تعديل العنوان المخصص",
      shortDes: "يرجى إدخال الوصف القصير",
      dialogLimit: "يرجى إدخال حد المحادثة",
      All: "الجميع",
      bindTips:
        "كن مستعدًا لتأكيد رمز PIN الخاص بحسابك على LinkedIn بسبب التحقق بخطوتين. سيتم إرساله بواسطة LinkedIn إلى عنوان البريد الإلكتروني لحسابك على LinkedIn. بناءً على إعدادات الأمان، قد يتم إرساله أيضًا عبر الرسائل النصية القصيرة (SMS).",
      inputCode: "يرجى إدخال الرمز",
      confirmDel: "هل أنت متأكد أنك تريد حذف هذه المهمة؟",
      NextStep: "التالي",

      Account: "الحساب",
      TimeZone: "المنطقة الزمنية",
      Proxy: "الوكيل",
      Connected: "متصل",
      ObtainProxyIP: "الحصول على عنوان IP للوكيل",
      VerifyLink: "التحقق من كلمة مرور حساب LinkedIn",
      LinkedInAccountLink: "رابط حساب LinkedIn",
      ContinueConnect: "متابعة الربط",
      selectHistory: "يتم تحميل البيانات",
      findUrl: " كيفية العثور على رابط LinkedIn",
      loginAccount: " قم بتسجيل الدخول إلى حساب LinkedIn الخاص بك",
      Enterkeywords: ` أدخل الكلمات الرئيسية في مربع البحث، ثم اضغط على Enter أو "عرض كل النتائج"`,
      SelectThePeople: `- اختر فلتر "الأشخاص"، وقم بتعيين المزيد من معايير البحث في الفلاتر، ثم انقر على "عرض النتائج"`,
      copyURL: ` انسخ عنوان URL من شريط التنقل إلى مربع الإدخال هذا`,
      ReferenceDocument: `- الوثيقة المرجعية`,
      conversationRestrictions: `- يرجى تحديد أي قيود على المحادثة، مثل عدم السماح بالنكات...`,
      Save: `حفظ`,
      day: "أيام",
      Hrs: "ساعات",
      Mins: "دقائق",
      Secs: "ثواني متبقية",
      LeaveupDialogTitle: 'انتظر!',
      LeaveupDialogTxt: 'ادفع الآن لتحصل على 7 أيام إضافية فوق الخصم الحالي',
      LeaveupDialogTimeDownTxt: 'العد التنازلي للخصم',
      LeaveupDialogBtnTxt: 'قم بالترقية الآن',
      LeaveupDialogGiveUpBtnTxt: 'التخلي عن الخصم',
    },
  },
};
