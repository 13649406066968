<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <div class="wrap-step">
        <a-steps :current="2">
          <a-step :title="$t('SelectSubscriptionPlan')" />
          <a-step :title="$t('BillingInformation')" />
          <a-step :title="$t('Payment')" />
        </a-steps>
      </div>
      <div class="wrap-pay">
        <div class="binfo">
          <span :class="[lang]">{{ $t("EnterPaymentDetails") }}</span
          ><a-button v-if="havAccount == 2" @click="havAccount = 1">{{
            $t("Cancel")
          }}</a-button>
        </div>
        <div class="form-box">
          <a-form :form="form" class="form" formLayout="vertical">
            <template>
              <div id="card-container">
                <a-row>
                  <a-col :span="24">
                    <a-form-item
                      class="cardItem"
                      :class="[lang]"
                      :label="$t('CardNumber')"
                    >
                      <div id="cardNumber" />
                      <p id="cardNumber-error" :style="{ color: 'red' }" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item
                      class="cardItem pr"
                      :label="$t('ExpirationDate')"
                    >
                      <div id="expiry" />
                      <p id="expiry-error" :style="{ color: 'red' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item
                      class="cardItem pl"
                      :class="[lang]"
                      :label="$t('SecurityCode')"
                    >
                      <div id="cvc" />
                      <p id="cvc-error" :style="{ color: 'red' }" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </template>
          </a-form>
        </div>
        <div class="tit">{{ $t("Order") }}</div>
        <ul v-if="$route.query.discount_code">
          <li>
            <div class="label">{{ $t("OriginalPrice") }}</div>
            <div class="cont">
              ${{ parseFloat($route.query.original_price).toFixed(2) }}
            </div>
          </li>
          <li>
            <div class="label">{{ $t("Discount") }}</div>
            <div class="cont">
              - ${{ parseFloat($route.query.discount_price).toFixed(2) }}
            </div>
          </li>
          <li>
            <div class="label">{{ $t("TotalPrice") }}</div>
            <div class="cont money">
              ${{ parseFloat($route.query.price).toFixed(2) }}
            </div>
          </li>
        </ul>
        <ul v-else>
          <li>
            <div class="label">{{ $t("OriginalPrice") }}</div>
            <div class="cont">
              ${{ parseFloat($route.query.original_price).toFixed(2) }}
            </div>
          </li>
          <!-- <li>
          <div class="label">Referral Discount</div>
          <div class="cont">- $0.0</div>
        </li> -->
          <li>
            <div class="label" :class="[lang]">{{ $t("TotalPrice") }}</div>
            <div class="cont money">
              ${{ parseFloat($route.query.price).toFixed(2) }}
            </div>
          </li>
        </ul>
        <div class="buttons">
          <a-button size="large" @click="back" class="btn">{{
            $t("Back")
          }}</a-button>
          <a-button
            size="large"
            id="confirm"
            @click="triggerConfirm"
            class="btn"
            type="primary"
            >{{ $t("Purchase") }}</a-button
          >
        </div>
      </div>
    </div>
    <LeaveupDialog
      @callback="callback"
      @goPay="triggerConfirm"
      ref="leaveDialog"
    ></LeaveupDialog>
  </a-spin>
</template>

<script>
import dayjs from "dayjs";
import LeaveupDialog from "./components/leaveupDialog";
import {
  createElement,
  createPaymentConsent,
  loadAirwallex,
  getElement,
} from "airwallex-payment-elements";
const elementsReady = {
  cardNumber: false,
  expiry: false,
  cvc: false,
};
const elementsCompleted = {
  cardNumber: false,
  expiry: false,
  cvc: false,
};
export default {
  data() {
    return {
      havAccount: 0,
      codeShow: false,
      form: this.$form.createForm(this),
      cardList: [],
      discountInfo: null,
      errorinfo: "",
      loading: true,
      payed: false,
      orderInfo: {},
      toPath: null,
    };
  },
  i18n: require("../i18n"),
  created() {
    this.getLocationList();
    // this.getCardList();
  },
  components: { LeaveupDialog },
  mounted() {
    this.init();
    this.splitRender();
    // this.saveHandler();
  },

  beforeRouteLeave(to, from, next) {
    next();
    // this.toPath = to;
    // if (!this.payed) {
    //   this.$refs["leaveDialog"].showPayGuide = true;
    // } else {
    //   next();
    // }
  },
  methods: {
    callback() {
      this.payed = true;
      this.$router.push({
        path: this.toPath.fullPath,
      });
    },
    init() {
      {
        // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
        loadAirwallex({
          env: process.env.VUE_APP_PAY_MOD, // Can choose other production environments, 'staging | 'demo' | 'prod'
          origin: window.location.origin, // Setup your event target to receive the browser events message
          locale: this.lang == "AR" ? "ar" : this.lang == "CN" ? "zh" : "en",
          // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
        }).then(() => {
          // STEP #4: Create and mount the individual card elements
          const cardNumEle = createElement("cardNumber");
          const cvcEle = createElement("cvc");
          const expiryEle = createElement("expiry");
          // STEP #5: Mount split card elements
          cardNumEle.mount("cardNumber"); // This 'cardNumber' id MUST MATCH the id on your cardNumber empty container created in Step 3
          cvcEle.mount("cvc"); // Same as above
          expiryEle.mount("expiry"); // Same as above
        });
      }
    },
    splitRender() {
      const cardNumberElement = document.getElementById("cardNumber");
      const expiryElement = document.getElementById("expiry");
      const cvcElement = document.getElementById("cvc");
      const domElementArray = [cardNumberElement, expiryElement, cvcElement];
      domElementArray.forEach((element) => {
        if (!element) {
          return;
        }
        element.addEventListener("onReady", this.onReady);
        element.addEventListener("onChange", this.onChange);
        element.addEventListener("onError", this.onError);
        element.addEventListener("onFocus", this.onFocus);
        element.addEventListener("onBlur", this.onBlur);
      });
    },
    onReady(event) {
      const { type } = event.detail;
      // eslint-disable-next-line no-prototype-builtins
      if (elementsReady.hasOwnProperty(type)) {
        elementsReady[type] = true; // Set element ready state
      }
      console.log(elementsReady);
      if (!Object.values(elementsReady).includes(false)) {
        this.loading = false;
        // document.getElementById("loading").style.display = "none"; // Example: hide loading state when element is mounted
        document.getElementById("card-container").style.display = "block"; // Example: show element when mounted
      }
    },
    onChange() {
      const { type, complete } = event.detail;
      // eslint-disable-next-line no-prototype-builtins
      if (elementsCompleted.hasOwnProperty(type)) {
        elementsCompleted[type] = complete; // Set element completion state
      }

      // Check if all elements are completed, and set submit button disabled state
      const allElementsCompleted =
        !Object.values(elementsCompleted).includes(false);
      console.log(allElementsCompleted);
      document.getElementById("confirm").disabled = !allElementsCompleted;
    },
    onError(event) {
      /*
    ... Handle event on error
  */
      const { error } = event.detail;
      document.getElementById("error").style.display = "block"; // Example: show error block
      document.getElementById("error").innerHTML = error.message; // Example: set error message
      console.log("There was an error", event.detail.error);
    },
    onFocus(event) {
      const { type } = event.detail;
      const element = document.getElementById(`${type}-error`);
      if (element) {
        element.innerHTML = ""; // Example: clear input error message
      }
      // Customize your input focus style by listen onFocus event
    },
    onBlur(event) {
      const { error, type } = event.detail;
      const element = document.getElementById(`${type}-error`);
      if (element && error) {
        element.innerHTML = error.message || JSON.stringify(error); // Example: set input error message
      }
    },
    async triggerConfirm() {
      const cardNumElement = getElement("cardNumber");
      let { intent_id, client_secret, airwallex_customer_id } =
        this.$route.query;
      this.loading = true;
      createPaymentConsent({
        mode: "recurring",
        intent_id: intent_id, // intent id(Optional)
        customer_id: airwallex_customer_id, // customer id
        client_secret: client_secret, // client secret (from creating intent or generating client secret)
        currency: "USD", // currency
        element: cardNumElement, // either the card element or card number element depends on the element you integrate,
        next_triggered_by: "merchant", // 'merchant' for MIT and 'customer' for CIT
      })
        .then((response) => {
          // Listen to the request response
          /* Handle response */
          // window.alert(JSON.stringify(response));

          console.log(response);
          if (response === false) {
            this.loading = false;
            return;
          }

          let { new_order_id, campaign_type, upgrade_free, originUrl } =
            this.$route.query;
          this.getUserInfo();
          this.$axios(
            "/payment/save_consents",
            {
              consents_id: response.payment_consent_id,
              order_id: parseInt(new_order_id),
            },
            "post"
          );
          if (upgrade_free == 1 && campaign_type == 1) {
            this.getUserInfo().then(() => {
              this.loading = false;
              if (originUrl) {
                this.$router.replace({
                  path: originUrl,
                });
              } else {
                this.$router.replace({
                  path: "/sales/salesReps/home",
                });
              }
            });
          } else {
            this.loading = false;
            this.$router.replace({
              path: "/setting/bindAccount",
              query: { id: new_order_id, campaign_type },
            });
          }
        })
        // STEP #6c: Listen to errors
        .catch((error) => {
          this.loading = false;
          /**
           * ... Handle event on error
           */
          this.$message.error(error.message);
        });
    },
    back() {
      this.$router.go(-1);
    },
    getCardList() {
      this.$axios("/payment/credit_card_list", {}, "post").then((res) => {
        if (res.code == 200) {
          this.cardList = res.data || [];
          if (res.data && res.data.length > 0) {
            this.havAccount = 1;
          }
        }
      });
    },

    validDiscountCode($event) {
      if (
        !$event.target.value ||
        ($event.target.value && !$event.target.value.trim())
      ) {
        this.discountInfo = null;
        this.errorinfo = null;
        return;
      }
      this.$axios(
        "/payment/discount_code_check",
        {
          discount_code: $event.target.value.trim(),
          subscription_plan_id: parseInt(this.$route.query.id),
        },
        "post"
      ).then((res) => {
        if (res.code == 200) {
          this.discountInfo = res.data;
        } else {
          this.errorinfo = res.msg;
        }
      });
    },
    showCode() {
      this.codeShow = true;
    },
    addNew() {
      this.havAccount = 2;
      this.discountInfo = null;
    },
    getDate() {
      return dayjs(Date.now()).format("YYYY-MM-DD");
    },
    validDate() {},
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 0px 30px 20px 30px;
}
.wrap-step {
  padding-top: 53px;
  margin: 0 auto;
  width: 800px;
  /deep/.ant-steps-item-title::after {
    background-color: #e1e1e1;
    height: 2px;
  }
  /deep/.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #e1e1e1;
  }
  /deep/.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #e1e1e1;
  }
}
.discode {
  font-weight: 400;
  font-size: 14px;
  color: #0e756a;
  cursor: pointer;
  .icon {
  }
}
.wrap-pay {
  width: 930px;
  margin: 0 auto;
  margin-top: 28px;
  padding: 40px;
  background: #ffffff;
  border-radius: 4px;
  .tit {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 40px;
    padding-top: 40px;
    border-top: #e5e5e5 solid 1px;
  }
  ul li {
    height: 41px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .label,
    .cont {
      height: 41px;
      line-height: 41px;
    }
    .cont {
      text-align: right;
      font-weight: bold;
      font-size: 24px;
      color: #000000;
    }
    .label {
      font-weight: bold;
      font-size: 20px;
      color: #666666;
    }
    .money {
      color: #0e756a;
      font-size: 30px;
    }
  }
}
.binfo {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  // padding-top: 40px;
  // margin-bottom: 30px;
  // border-top: #e5e5e5 solid 1px;
  display: flex;
  justify-content: space-between;
}
.pr {
  padding-right: 45px;
}
.pl {
  padding-left: 45px;
}
.buttons {
  text-align: center;
  margin-top: 30px;
  .btn {
    width: 200px;
    margin-left: 20px;
  }
}
.addNew {
  width: 144px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 2px solid #0e756a;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.errorInfo {
  color: #ff4e3a;
  margin-bottom: 0;
}
.discountTips {
  font-weight: 400;
  font-size: 14px;
  color: #ffb175;
}
.field-container {
  margin: 0 auto 16px;
  width: 300px;
}
.field-container div {
  border: 1px solid;
  padding: 4px 8px;
  margin-top: 8px;
}
div.field-label {
  text-align: left;
  border: 0;
}
.cardItem {
  margin-bottom: 0;
}
/* Custom styling for the inputs */
#cardNumber,
#cvc,
#expiry {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 8px;
  height: 28px;
  box-sizing: content-box;
}
</style>
